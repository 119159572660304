<template>
    <div>
        
    </div>
</template>

<script>
export default {
    data () {
        return {
        }
    },
    created(){
        this.alterDetails(this.$route.query.userId,this.$route.query.custGlobalId,this.$route.query.familyId,this.$route.query.familyMemberId,this.$route.query.orgCode,)
    },
    methods:{
        alterDetails(userId,custGlobalId,familyId,familyMemberId,orgCode){
            this.$router.push(`/linehomeaddform?isEdit=true&userId=${userId}&custGlobalId=${custGlobalId}&familyId=${familyId}&familyMemberId=${familyMemberId}&orgCode=${orgCode}&isLineHome=${this.$route.query.isLineHome}&page=${this.$route.query.page}`);
        },
    }
}
</script>
 
<style lang = "less" scoped>
    
</style>